var app = window.app = window.app || {}
var start = Date.now()

function db () {
  if (!app.db) {
    var firebase = window.firebase
    firebase.initializeApp({
      apiKey: 'AIzaSyD5NaeeeGcV8QBvc5GBMlVA3OApchsWE9E',
      authDomain: 'testem-landing.firebaseapp.com',
      databaseURL: 'https://testem-landing.firebaseio.com',
      projectId: 'testem-landing'
    })

    var db = firebase.firestore()
    db.settings({ timestampsInSnapshots: true })

    app.db = db
  }

  return app.db
}

window.app.requestAccess = function requestAccess (email) {
  if (!email) return

  var meta = {}
  try {
    meta = {
      ua: window.navigator.userAgent,
      url: window.location.href,
      delay: Date.now() - start,
      screen: {
        width: window.screen.width,
        height: window.screen.height
      }
    }
  } catch (err) {}

  return db().collection('access-requests').add({
    email: email,
    ts: new Date(),
    meta: meta
  }).then(function (docRef) {
    return { done: true }
  })
}

window.app.listRequests = function listRequests () {
  db().collection('access-requests').get().then((list) => {
    list.forEach((doc) => {
      console.log(`${doc.id} => ${doc.data()}`)
    })
  })
}

document.addEventListener('submit', (e) => {
  e.preventDefault()
  var form = e.target
  var input = form.querySelector('input')
  var button = form.querySelector('button')
  app.requestAccess(input.value)
  input.value = ''
  input.setAttribute('disabled', true)
  button.setAttribute('disabled', true)
  form.classList.add('thanks')
})
